// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-dpad-tsx": () => import("./../../../src/pages/about-dpad.tsx" /* webpackChunkName: "component---src-pages-about-dpad-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-ansji-tsx": () => import("./../../../src/pages/ansji.tsx" /* webpackChunkName: "component---src-pages-ansji-tsx" */),
  "component---src-pages-community-service-wg-tsx": () => import("./../../../src/pages/community-service-wg.tsx" /* webpackChunkName: "component---src-pages-community-service-wg-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dpad-calendar-tsx": () => import("./../../../src/pages/dpad-calendar.tsx" /* webpackChunkName: "component---src-pages-dpad-calendar-tsx" */),
  "component---src-pages-dpad-news-tsx": () => import("./../../../src/pages/dpad-news.tsx" /* webpackChunkName: "component---src-pages-dpad-news-tsx" */),
  "component---src-pages-economic-working-group-tsx": () => import("./../../../src/pages/economic-working-group.tsx" /* webpackChunkName: "component---src-pages-economic-working-group-tsx" */),
  "component---src-pages-education-working-group-tsx": () => import("./../../../src/pages/education-working-group.tsx" /* webpackChunkName: "component---src-pages-education-working-group-tsx" */),
  "component---src-pages-health-working-group-tsx": () => import("./../../../src/pages/health-working-group.tsx" /* webpackChunkName: "component---src-pages-health-working-group-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-international-black-health-conference-tsx": () => import("./../../../src/pages/international-black-health-conference.tsx" /* webpackChunkName: "component---src-pages-international-black-health-conference-tsx" */),
  "component---src-pages-justice-working-group-tsx": () => import("./../../../src/pages/justice-working-group.tsx" /* webpackChunkName: "component---src-pages-justice-working-group-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-our-members-tsx": () => import("./../../../src/pages/our-members.tsx" /* webpackChunkName: "component---src-pages-our-members-tsx" */),
  "component---src-pages-pan-canadian-black-food-sovereignty-caucus-tsx": () => import("./../../../src/pages/pan-canadian-black-food-sovereignty-caucus.tsx" /* webpackChunkName: "component---src-pages-pan-canadian-black-food-sovereignty-caucus-tsx" */),
  "component---src-pages-reparation-working-group-tsx": () => import("./../../../src/pages/reparation-working-group.tsx" /* webpackChunkName: "component---src-pages-reparation-working-group-tsx" */),
  "component---src-pages-working-groups-tsx": () => import("./../../../src/pages/working-groups.tsx" /* webpackChunkName: "component---src-pages-working-groups-tsx" */),
  "component---src-pages-youth-working-group-tsx": () => import("./../../../src/pages/youth-working-group.tsx" /* webpackChunkName: "component---src-pages-youth-working-group-tsx" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

